.form-table {
  .ant-form-item {
    margin-bottom: 0;
  }
}

.col-main-title {
  span {
    max-width: 150px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}
