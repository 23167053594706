.product-table {
  border: 1px solid #ddd;

  tr {
    border-bottom: 1px solid #ddd;

    &:nth-child(even) {
      background-color: #eee;
    }
  }

  td,
  th {
    padding: 10px 15px;
  }

  th {
    font-weight: 500;
  }
}

.currency-block {
  float: left;
  width: 100%;
  background-color: #f5f5f5;
  border: 1px solid #d9d9d9;
  height: 32px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  padding: 0 11px;
  color: rgba(0, 0, 0, 0.25);
  border-radius: 2px;

  .ant-tag {
    opacity: 0.7;
  }
}
