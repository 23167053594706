.header {
   box-shadow: 0 1px 5px rgba(0, 0, 0, 0.2);
   position: relative;
   z-index: 2;
}

.branding-block {
   height: 100%;
   display: flex;
   align-items: center;
   justify-content: center;
}

.branding-link {
   padding: 0 20px;
   width: 100%;
   display: block;
   text-align: left;
   height: auto;


   img {
      width: 100%;
      max-width: 180px;
   }
}

.header-cart {
   position: relative;
   font-size: 20px;
   color: var(--bs-gray-600);
   display: inline-block;
   padding: 0;
   margin-right: 20px;

   .cart-count {
      display: inline-block;
      position: absolute;
      right: -10px;
      top: 0;
      background-color: var(--bs-secondary);
      font-size: 11px;
      color: white;
      line-height: 1;
      padding: 2px 3px;
      border-radius: 100px;
   }

   &:focus {
      .cart-count {
         position: absolute;
      }
   }
}
