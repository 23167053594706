@import "src/assets/styles/theme";

html, body {
  height: 100%;
  min-height: 100%;
}

* {
  -moz-font-smoothing: antialiased;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility!important;
  text-shadow: 1px 1px 1px rgb(0 0 0 / 0%);
}

#root {
  height: 100%;
  min-height: 100%;
  display: grid;
}

/* #root > .ant-layout {
  height: 100%;
  min-height: 100%;
} */

table {
  td, th {
    word-break: break-all;
  }
}

.login-form {
  width: 40%;
  max-width: 600px;
  margin: auto;
  padding: 20px 48px;
  border: 1px solid #ddd;
  transition: 0.3s;
  border-radius: 5px;
  background-color: white;
}

.loginBtnbutton[type=submit] {
  display: block;
  width: 100%;
  margin: 3% auto;
  color: #fff;
  background-color: rgb(243, 101, 35);
  border-color: rgb(243, 101, 35);
  font-weight: 600;
}

.loginBtn[type=submit]:hover {
  color: #fff;
  background-color: rgb(243, 101, 35);
  border-color: rgb(243, 101, 35);
}

.branding-logo {
  padding-top: 7px;
  padding-bottom: 25px;
}

.logo {
  height: 75px;
  display: block;
  margin: 0 auto;
}

.loginBtn  {
  font-size: 18px;
  width: 150px;
  height: 42px;

  &:disabled {
    //color: rgba(255,255,255,0.5);
    opacity: 0.5;
    color: white;

    span {
      color: white;
    }
  }

  &:focus {
    color: white !important;
  }
}

.signup-block {
  display: inline-block;
  padding-left: 15px;
  font-size: 18px;
}

.joinNowLink {
  font-size: 18px;
  cursor: pointer;
  padding: 0 0 0 10px;

  &:hover {
    span {
      text-decoration: underline;
    }
  }
}

@import "./assets/styles/common";

.login-signup-header {
  position: relative;
  /* border-bottom: 1px solid var(--bs-gray-300); */
  justify-content: flex-end;
  text-align: right;
  padding-bottom: 10px;
  margin-bottom: 15px;

  .login-signup-main-title {
    font-size: 22px;
    color: var(--bs-primary);
    /* padding-bottom: 5px; */
  }

  .login-signup-short-info {
    font-size: 14px;
    color: #999;
  }

}

.each-login-signup-benefit {
  text-align: left;
  padding-top: 16px;
  padding-bottom: 16px;
  display: flex;
  align-items: center;
  flex-direction: row;

  .each-benefit-icon {
    float: left;
    width: 60px;
    height: 60px;
    background-color: var(--bs-gray-200);
    border-radius: 100px;
  }

  .each-benefit-content {
    float: left;
    width: calc(100% - 60px);
    padding-left: 20px;
    font-size: 17px;
    font-weight: 500;
  }
}

.signupCheckbox {
  font-size: 13px;
}

.login-block {
  .ant-form-item {
    margin-bottom: 20px;
  }

  .ant-form-item-explain{
    &.ant-form-item-explain-error {
      text-align: left;
      padding-top: 3px;
    }
  }
}

.list-data {
  li {
    margin-bottom: 20px;
  }
}


.table-striped {
  tr {
    &:nth-child(even) {
      background-color: #eee;
    }
  }
}

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.timeZoneCol {
  max-width: 200px;
  width: 200px;
}

.email-truncate {
  max-width: 200px;
}