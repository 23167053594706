//@import "./botostrap/bootstrap";
@import "bootstrap";
@import "form";

$leftSidebar: 260px;

:root {
   /* --primary: #F26522;
   --secondary: #404041; */
   --left-sidebar: $leftSidebar;
}

.full-width {
   float: left;
   width: 100%;
}

.branding-logo {
   .logo {
      max-height: 50px
   }
}

.main {
   min-height: 100vh;
   /* flex-wrap: wrap; */
}

.ant-menu-item-group-title {
   padding: 0;
}

.page-header {
   border-bottom: 1px solid #ddd;
   padding: 12px 20px;
   background-color: var(--bs-gray-300);

   .page-title {
      font-size: 18px;
      font-weight: 500;

      span {
         font-style: italic;
      }
   }
}

.btn {
   border: 1px solid #ccc;
   background-color: #fff;
   font-size: 16px;
   color: #333;
}

.btn-primary {
   background-color: var(--bs-primary);
   border-color: var(--bs-primary);
   color: white;


   &:hover {
      color: white;
      filter: brightness(1.15);
   }
}

.btn-secondary {
   background-color: var(--bs-secondary);
   border-color: var(--bs-secondary);
   color: white;

   &:hover {
      color: white;
      filter: brightness(1.15);
   }
}

/* .text-truncate {
   overflow: hidden;
   text-overflow: ellipsis;
   white-space: nowrap;
} */



.main-content-block {
   display: flex;
   flex-direction: row;
   align-items: stretch;

   .left-sidebar {
     /*  float: left;
      width: 260px;
      min-width: 260px;
      max-width: 260px; */
      background-color: white;
      flex: 0 0 $leftSidebar;
   }

   .main-content {
      /*float: left;
       width: calc(100% - 260px);
      min-width: calc(100% - 260px);
      max-width: calc(100% - 260px); */
      flex: 0 0 calc(100% - #{$leftSidebar});
      text-align: left;
      background-color: #f0f2f5;
      padding: 20px;
   }
}


.form-list {
   li {
      float: left;
      width: 100%;
   }


}

.fs-7 {
   font-size: 14px;
}

.fs-8 {
   font-size: 13px;
}

.fs-9 {
   font-size: 12px;
}

.list-gap-5 {
   li {
      margin-bottom: 5px;
   }
}

.list-gap-10 {
   li {
      margin-bottom: 10px;
   }
}

.grid-gap-5 {
   display: grid;
   grid-template-columns: 100px auto;
   column-gap: 15px;
   row-gap: 10px;
}


.form-list-2-col {
   display: grid;
   grid-template-columns: auto auto;
   column-gap: 30px;
}