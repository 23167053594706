.dashboard-card-icon {
   width: 55px;
   height: 55px;
   border-radius: 1000px;
   background-color: #eee;
   display: flex;
   align-items: center;
   justify-content: center;
   font-size: 30px;

}

.dashboard-card-title {
   display: flex;
   flex-direction: column;

   .card-big-title,
   .card-small-title {
      float: left;
      width: 100%;
   }

   .card-big-title {
      font-size: 22px;
      font-weight: bold;
   }

   .card-small-title {
      color: #777;
   }
}